<template functional>
  <tapered-section
    class="px-4 flex flex-wrap"
    :class="[data.class, data.staticClass]"
  >
    <div
      class="xl:mx-12 mx-auto border rounded-md border-gray-400 w-full max-w-2xl xl:max-w-none p-8 bg-white"
    >
      <header class="mb-4">
        <h2>
          {{ parent.$t(`${$options.tPfx}.exemptions`) }}
        </h2>
      </header>
      <ol class="list-outside list-decimal pl-6">
        <li class="mb-1">
          {{ parent.$t(`${$options.tPfx}.multipleEntrySchengenVisa`) }}
        </li>
        <li class="mb-1">
          {{ parent.$t(`${$options.tPfx}.foreignNationalsOfUsUkNire`) }}
        </li>
        <li class="mb-1">
            {{ parent.$t(`${$options.tPfx}.foreignCitizensWithoutVisa`) }}
        </li>
        <li class="mb-1">
          {{ parent.$t(`${$options.tPfx}.foreignersAreBoundByA10YearResidencePermit`) }}
        </li>
        <li class="mb-1">
            {{ parent.$t(`${$options.tPfx}.foreignersPermitAprilToDecemberWithPassport`) }}
        </li>
        <li class="mb-1">
            {{ parent.$t(`${$options.tPfx}.holdersOfDocuments`) }}
            <div class="ml-4">
               - {{ parent.$t(`${$options.tPfx}.alienTravelDocument`) }}
            </div>
            <div class="ml-4">
               - {{ parent.$t(`${$options.tPfx}.travelDocumentRefugees`) }}
            </div>
            <div class="ml-4">
               - {{ parent.$t(`${$options.tPfx}.travelDocumentStatelessPersons`) }}
            </div>
            <div class="ml-4">
               - {{ parent.$t(`${$options.tPfx}.travelDocumentSubsidiaryProtection`) }}
            </div>
        </li>
      </ol>

      <div class="card-for-link-preview">
        <a class="link-preview-header mb-2" :href="parent.$t(`${$options.tPfx}.link`)">{{ parent.$t(`${$options.tPfx}.visaRegimeForForeigners`) }} </a>
        <p class="mb-2"> {{ parent.$t(`${$options.tPfx}.visaRegimeForForeignersContent`) }} </p>
        <a :href="parent.$t(`${$options.tPfx}.link`)" class="link-preview-text"> {{ parent.$t(`${$options.tPfx}.link`) }} </a>
      </div>

    </div>
  </tapered-section>
</template>

<script>
import TaperedSection from "@/components/TaperedSection.vue";
export const translationPrefix = "view.apply.processPhases.exemptions";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      exemptions:
        "Exemption of visa to enter and stay in the Republic of Albania for up to 90 days in 180 days",
      multipleEntrySchengenVisa:
        "Foreign citizens who have a valid, multiple-entry Schengen visa, which has been previously used in one of the Schengen states, or foreign citizens who have a valid permit of stay in one of the Schengen states.",
      foreignNationalsOfUsUkNire:
        "Foreign citizens who have a valid, multiple-entry US or UK visa, which has been previously used in the respective country of issuance, or have valid permit of stay in the US or UK.",
      foreignersAreBoundByA10YearResidencePermit:
        "Foreign citizens who have a 10 year residence permit, issued by the competent authority of the United Arab Emirates, valid not less than one year from the moment of entry.",
      foreignCitizensWithoutVisa:
          "Foreign citizens who enter and stay without visa in the Schengen area.",
      foreignersPermitAprilToDecemberWithPassport:
          "Citizens from Saudi Arabia, Bahrein, Bahrain, Oman, Qatar and Thailand can enter without a visa for the period 6 March 2024 until 31 December 2024 with the document passport.",
      holdersOfDocuments:
        "Holders of documents for travel by EU member states as follows: ",
      alienTravelDocument:
          "Alien's travel document,",
      travelDocumentRefugees:
          "Travel document (Refugees, Geneva Convention of 28 July 1951),",
      travelDocumentStatelessPersons:
          "Travel document (Stateless persons, NY Convention of 28 September 1954),",
      travelDocumentSubsidiaryProtection:
          "Travel document for person enjoying subsidiary protection.",
      visaRegimeForForeigners: "Visa regime for foreign citizens",
      visaRegimeForForeignersContent: "The visa regime for foreign citizens in the Republic of Albania is provided by Law No. 79/2021 “On foreigners” and by the Decision of the Council of Ministers No. 858, dated 29.12.2021, “On the definition of the criteria of procedures and documentation for entry, stay and treatment of foreigners in the Republic of Albania”.",
      link: "https://punetejashtme.gov.al/en/regjimi-i-vizave-per-te-huajt/",
    },
    al: {
      exemptions:
        "Përjashtimi për tu pajisur me vizë për të hyrë dhe qëndruar në Republikën e Shqipërisë deri në 90 ditë për 180 ditë",
      multipleEntrySchengenVisa:
        "Të huajt që kanë një vizë të vlefshme Shengen, me shumë kalime, e cila është përdorur më parë në një nga vendet e zonës Shengen, ose që kanë një leje të vlefshme qëndrimi në një nga vendet e zonës Shengen.",
      foreignNationalsOfUsUkNire:
        "Të huajt që kanë një vizë të vlefshme të SHBA ose të Mbretërisë së Bashkuar, me shumë kalime, e cila është përdorur më parë në një nga këto vende, ose kanë leje të vlefshme qëndrimi në SHBA ose në Mbretërinë e Bashkuar.",
      foreignersAreBoundByA10YearResidencePermit:
        "Të huajt që janë të pajisur me leje qëndrimi 10 vjeçare, e lëshuar nga autoritetet e Emirateve të Bashkuara Arabe, e vlefshme jo më pak se një vit nga momenti i hyrjes.",
      foreignersPermitAprilToDecemberWithPassport:
        "Shtetasit nga Arabia Saudite, Bahrein, Katar, Oman dhe Tajlandë mund të udhëtojnë pa vizë për periudhën 6 mars 2024 deri më 31 dhjetor 2024 me dokumentin pasaportë.",
      foreignCitizensWithoutVisa:
          "Shtetasit e huaj që hyjnë dhe qëndrojnë në shtetet e zonës shengen pa vizë.",
      holdersOfDocuments:
          "Mbajtësit e dokumenteve të udhëtimi për të huaj të lëshuar nga vendet anëtare të BE-së: ",
      alienTravelDocument:
        "Alien's travel document,",
      travelDocumentRefugees:
        "Travel document (Refugees, Geneva Convention of 28 July 1951),",
      travelDocumentStatelessPersons:
        "Travel document (Stateless persons, NY Convention of 28 September 1954),",
      travelDocumentSubsidiaryProtection:
        "Travel document for person enjoying subsidiary protection.",
      visaRegimeForForeigners: "Regjimi i vizave për të huajt.",
      visaRegimeForForeignersContent: "Regjimi i vizave për të huajt përcaktohet në bazë të Ligjit, 79/2021, “Për të huajt”, dhe VKM nr. 858, datë 29.12.2021 për “Për përcaktimin e kritereve, të procedurave dhe dokumentacionit për hyrjen, qëndrimin dhe trajtimin e të huajve në RSh”.",
      link: "https://punetejashtme.gov.al/regjimi-i-vizave-per-te-huajt",
    },
  },
};

export default {
  components: { TaperedSection },
  tPfx: translationPrefix,
};
</script>

<style>
.card-for-link-preview {
  border-color: #bebfc3;
  border-width: 1px;
  text-align: left;
  padding: 15px;
  margin-bottom: 20px;
}

.link-preview-text {
  color: #187cbc;
  word-wrap: break-word;
}

.link-preview-header {
  color: #187cbc;
  font-size: 25px;
}
</style>