import querystring from 'querystring';
import client from "./client";

export default {
  track(trackingNumber) {
    return client.get(`/applications/check-status/${trackingNumber}`)
  },

  all(status = null) {
    let qs = '';

    if (status) {
      qs = `?${querystring.stringify({ status })}`;
    }

    return client.get(`/me/applications${qs}`);
  },

  create(form) {
    return client.post("/me/applications", form);
  },

  get(applicationId) {
    return client.get(`/me/applications/${applicationId}`);
  },

  finalize(applicationId) {
    return client.post(`/me/applications/${applicationId}/finalize`)
  },

  getPaymentDetails(applicationId) {
    return client.get(`/me/applications/${applicationId}/payment`)
  },

  finalizePayment(applicationId) {
    return client.post(`/me/applications/${applicationId}/payment/finalize`)
  },

  getPaymentURL(applicationId) {
    return client.get(`/me/applications/${applicationId}/payment-url`)
  }
};
