import STATUS_ENUM from '@/utils/applicationStatusEnum';

const statusToTailwindClasses = (status, statusString) => {
  const isLongString = statusString.length > 16;

  const classesForLongString = isLongString ? 'badge-compressed ' : 'badge-base ';

  switch (status) {
    case STATUS_ENUM.draft:
      return classesForLongString + 'badge-yellow';
    case STATUS_ENUM.open:
      return classesForLongString + 'badge-blue';
    case STATUS_ENUM.consularInProgress:
      return classesForLongString + 'badge-blue';
    case STATUS_ENUM.consularNeedChanges:
      return classesForLongString + 'badge-secondary';
    case STATUS_ENUM.waitingForPayment:
      return classesForLongString + 'badge-secondary';
    case STATUS_ENUM.waitingFerPayment:
      return classesForLongString + 'badge-secondary';
    case STATUS_ENUM.verifyPayment:
      return classesForLongString + 'badge-yellow';
    case STATUS_ENUM.unpaid:
      return classesForLongString + 'badge-secondary';
    case STATUS_ENUM.paid:
      return classesForLongString + 'badge-indigo';
    case STATUS_ENUM.ferPaid:
      return classesForLongString + 'badge-indigo';
    case STATUS_ENUM.rejected:
      return classesForLongString + 'badge-secondary';
    case STATUS_ENUM.rejectedAKPA:
      return classesForLongString + 'badge-secondary';
    case STATUS_ENUM.dckInProgress:
      return classesForLongString + 'badge-blue';
    case STATUS_ENUM.verificationInProgress:
      return classesForLongString + 'badge-blue';
    case STATUS_ENUM.shishInProgress:
      return classesForLongString + 'badge-blue';
    case STATUS_ENUM.pkInInProgress:
      return classesForLongString + 'badge-blue';
    case STATUS_ENUM.shishApproved:
      return classesForLongString + 'badge-blue';
    case STATUS_ENUM.pkApproved:
      return classesForLongString + 'badge-blue';
    case STATUS_ENUM.shishRejected:
      return classesForLongString + 'badge-blue';
    case STATUS_ENUM.pkRejected:
      return classesForLongString + 'badge-blue';
    case STATUS_ENUM.verifiedByShishPk:
      return classesForLongString + 'badge-blue';
    case STATUS_ENUM.verifiedByMinistry:
      return classesForLongString + 'badge-blue';
    case STATUS_ENUM.approved:
      return classesForLongString + 'badge-green';
    case STATUS_ENUM.printedGenerated:
      return classesForLongString + 'badge-green';
    case STATUS_ENUM.notShowedUp:
      return classesForLongString + 'badge-secondary';
    case STATUS_ENUM.canceled:
      return classesForLongString + 'badge-secondary';
  }
};

export default statusToTailwindClasses;
